import React from "react";
import logo from "./../src/assets/escronia.png";

const ArbiterEscrows = ({ escrows, getStateText, resolveDispute, EscrowStates }) => {
  const getBoxStyles = (state, boxType) => {
    const isActive =
      (state === EscrowStates.PAID_TO_ESCROW && boxType === "escrow") ||
      (state === EscrowStates.COMPLETE && boxType === "seller") ||
      (state === EscrowStates.REFUNDED && boxType === "buyer") ||
      (state === EscrowStates.DISPUTE && boxType === "escrow");

    if (isActive) {
      if (state === EscrowStates.REFUNDED) return "bg-yellow-500 text-black border border-accent";
      if (state === EscrowStates.DISPUTE) return "bg-red-500 text-white border border-accent";
      return "bg-green-500 text-white border border-accent";
    }

    return "bg-gray-300 text-black";
  };

  return (
    <div>
      <h3 className="text-lg font-bold text-accent">As Arbiter</h3>
      {escrows.length > 0 ? (
        escrows.map((escrow) => (
          <div
            className="escrow-card transition-transform transform hover:scale-105 p-4 bg-gray-100 rounded-md shadow-md"
            key={escrow.id}
          >
            <p>
              Escrow ID: <span className="font-semibold">{escrow.id}</span>
            </p>
            <p>
              Buyer: <span className="font-semibold">{escrow.buyer}</span>
            </p>
            <p>
              Seller: <span className="font-semibold">{escrow.seller}</span>
            </p>
            <p>
              Amount: <span className="font-semibold">{escrow.amount} USDT</span>
            </p>
            <p>
              Status: <span className="font-semibold">{getStateText(escrow.currentState)}</span>
            </p>

            {/* Schematic Diagram */}
            <div className="flex justify-center items-center space-x-4 my-4">
              {/* Buyer */}
              <div
                className={`w-32 h-32 flex flex-col items-center justify-center rounded-md ${getBoxStyles(
                  escrow.currentState,
                  "buyer"
                )}`}
              >
                <span>Buyer</span>
                {escrow.currentState === EscrowStates.REFUNDED && (
                  <img src={logo} alt="Escrownia Logo" className="w-12 h-12 mt-2" />
                )}
              </div>

              {/* Escrow */}
              <div
                className={`w-32 h-32 flex flex-col items-center justify-center rounded-md ${getBoxStyles(
                  escrow.currentState,
                  "escrow"
                )}`}
              >
                {escrow.currentState === EscrowStates.PAID_TO_ESCROW && (
                  <>
                    <span>In Escrow</span>
                    <img src={logo} alt="Escrownia Logo" className="w-12 h-12 mt-2" />
                  </>
                )}
                {escrow.currentState === EscrowStates.DISPUTE && (
                  <>
                    <span>Dispute</span>
                    <img src={logo} alt="Escrownia Logo" className="w-12 h-12 mt-2" />
                  </>
                )}
                {escrow.currentState !== EscrowStates.PAID_TO_ESCROW &&
                  escrow.currentState !== EscrowStates.DISPUTE && <span>Escrow</span>}
              </div>

              {/* Seller */}
              <div
                className={`w-32 h-32 flex flex-col items-center justify-center rounded-md ${getBoxStyles(
                  escrow.currentState,
                  "seller"
                )}`}
              >
                <span>Seller</span>
                {escrow.currentState === EscrowStates.COMPLETE && (
                  <img src={logo} alt="Escrownia Logo" className="w-12 h-12 mt-2" />
                )}
              </div>
            </div>

            {/* Action Buttons */}
            {escrow.currentState === EscrowStates.DISPUTE && (
              <div className="mt-4">
                <button
                  className="bg-green-500 hover:bg-green-600 m-3 px-4 py-2 rounded-md"
                  onClick={() => resolveDispute(escrow.id, true)}
                >
                  Release to Seller
                </button>
                <button
                  className="bg-red-500 hover:bg-red-600 m-3 px-4 py-2 rounded-md"
                  onClick={() => resolveDispute(escrow.id, false)}
                >
                  Refund to Buyer
                </button>
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="text-secondary">No escrows found.</p>
      )}
    </div>
  );
};

export default ArbiterEscrows;
