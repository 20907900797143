import React from "react";
import escronia from "./../src/assets/escronia.png";
import { Link } from "react-router-dom";

const LandingPage = () => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    return (
        <div className="text-white min-h-screen flex flex-col justify-between">
            <main className="flex-grow flex flex-col items-center justify-center text-center px-4">
                <img
                    src={escronia}
                    alt="Escrownia Logo"
                    width={"220em"}
                    className="mb-6"
                />

                <h2 className="text-4xl font-bold mb-6">
                    Revolutionizing Secure Transactions
                </h2>
                <p className="text-lg mb-6">
                    Welcome to Escrownia - your trusted blockchain-based escrow platform.
                    Ensure secure, transparent, and tamper-proof transactions for all your
                    dealings, backed by blockchain technology.
                </p>
                <p className="text-lg mb-8">
                    We support transactions in USDT (Tether), the world's leading stablecoin,
                    providing you with stability and reliability in volatile markets.
                </p>
                <div className="flex flex-wrap justify-center gap-4">
                    <Link
                        to="/how-to-use"
                        className="border border-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-white hover:text-black transition-colors duration-200"
                    >
                        How to Use
                    </Link>
                    <Link
                        to="/about"
                        className="border border-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-white hover:text-black transition-colors duration-200"
                    >
                        About
                    </Link>

                    {isMobileDevice && (
                        <a
                            href="https://metamask.app.link/dapp/escrownia.com"
                            className="bg-accent text-black px-6 py-3 rounded-lg text-lg font-semibold hover:bg-white transition-colors duration-200"
                        >
                            Open in MetaMask
                        </a>
                    )}
                </div>
            </main>
        </div>
    );
};

export default LandingPage;
