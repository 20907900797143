import React from "react";

const CreateEscrow = ({
  seller,
  setSeller,
  amount,
  setAmount,
  selectedToken,
  setSelectedToken,
  tokens,
  createEscrow,
}) => {
  return (
    <div className="create-escrow-form bg-secondary p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">Create Escrow</h2>
      <div className="space-y-4">
        <select
          value={selectedToken}
          onChange={(e) => setSelectedToken(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-primary text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:outline-none"
        >
          {tokens.map(({ name, address }) => (
            <option key={address} value={address}>
              {name}
            </option>
          ))}
        </select>
        <small>
          Select the token to pay
        </small>
        <input
          type="text"
          placeholder={`Pay To: (${selectedToken ? selectedToken : "0x..."})`}
          value={seller}
          onChange={(e) => setSeller(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-primary text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
 <small>
          Enter the wallet address of the person to whom the payment will go 
        </small>
        <input
          type="number"
          placeholder={`Amount: e.g. 22`}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-primary text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
         <small>
          Enter the token amount to pay
        </small>
        <button
          onClick={createEscrow}
          className="w-full px-4 py-2 bg-accent text-white font-semibold rounded-lg hover:bg-blue-400 transition-colors duration-300"
        >
          Create Escrow
        </button>
        <small>
          The payment will be stored in a smart contract. When the you receive the product or service for which the payment is created, then approve the payment. If the parties have a dispute, then shift the payment to the dispute. An arbiter will listen the parties and solve the problem. The seller can also cancel the payment to refund to the payee. 
        </small>
      </div>
    </div>
  );
};

export default CreateEscrow;
