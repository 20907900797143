import React from "react";
import ProgressTimeline from "./ProgressTimeline";

const RightColumn = ({ transactionCommission, disputeCommission, progress, Coin }) => {
    console.log("RightColumn Progress:", progress); // Debugging log
    return (
        <div className="bg-secondary p-4 rounded-lg shadow-md text-white">
            <div className="bg-secondary p-4 rounded-lg shadow-md text-white">
                <h3 className="text-lg font-bold">Commission Details</h3>
                <p>Transaction: {transactionCommission} {Coin}</p>
                <p>Dispute Resolution: {disputeCommission} {Coin}</p>
            </div>

            <ProgressTimeline progress={progress} />
        </div>
    );
};


export default RightColumn;
