import React from "react";
import Footer from "./Footer";
import Navbar1 from "./NavBar1";

const HowToUse = () => {
  return (
    <>
      <Navbar1 />
      <div className="container mx-auto py-8 px-6">
        <h1 className="text-3xl font-bold mb-6">How to Use Escrownia</h1>
        <p className="mb-4">
          Escrownia is a secure payment platform designed to ensure that all transactions between buyers and sellers are safe and problem-free. Follow the steps below to use the app:
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Connect Your Wallet</h2>
        <p className="mb-4">
          To get started, connect your cryptocurrency wallet (e.g., MetaMask) to Escrownia. Ensure your wallet is loaded with USDT and some ETH for transaction fees.
        </p>

        <h2 className="text-2xl font-semibold mb-4">2. Create an Escrow</h2>
        <p className="mb-4">
          - Enter the seller’s wallet address and the transaction amount. <br />
          - Submit the escrow, and funds will be securely held until the transaction is completed.
        </p>

        <h2 className="text-2xl font-semibold mb-4">3. Approve Payments</h2>
        <p className="mb-4">
          Once the transaction conditions are met, approve the payment to release funds to the seller.
        </p>

        <h2 className="text-2xl font-semibold mb-4">4. Resolve Disputes</h2>
        <p className="mb-4">
          If there is an issue with the transaction, initiate a dispute and let an arbiter resolve it.
        </p>

        <p className="mt-6">
          For further assistance, contact us at <a href="mailto:support@escrownia.com" className="text-accent">support@escrownia.com</a>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default HowToUse;
