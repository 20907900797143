import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import HowToUse from "./HowToUse";
import About from "./About";
import App from "./App";
import { useState } from "react";

const MainApp = () => {
  const [network, setNetwork] = useState(null);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<App setNetwork={setNetwork} network={network} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/how-to-use" element={<HowToUse />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
};

export default MainApp;


