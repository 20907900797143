import React from "react";
import Navbar1 from "./NavBar1";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar1 />
      <div className="container mx-auto py-8 px-6">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <p className="mb-4">
          Your privacy is important to us. This Privacy Policy outlines how we collect, use, and safeguard your information.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <p className="mb-4">
          - Personal Information: We may collect your name, email address, and other identifying information when you use our app.
          <br />
          - Usage Data: We collect data about how you interact with the app to improve functionality and performance.
        </p>

        <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <p className="mb-4">
          - To provide and maintain the app. <br />
          - To notify you about updates or changes. <br />
          - To improve the user experience through analytics.
        </p>

        <h2 className="text-2xl font-semibold mb-4">3. Sharing Your Information</h2>
        <p className="mb-4">
          We do not share your personal information with third parties except as required by law or for essential services.
        </p>

        <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
        <p className="mb-4">
          We implement appropriate measures to protect your data from unauthorized access, alteration, or disclosure.
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
        <p className="mb-4">
          You have the right to access, update, or delete your personal information. Contact us at [your email] for any requests.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Changes to This Policy</h2>
        <p className="mb-4">
          We may update this policy from time to time. Any changes will be communicated through the app or email.
        </p>

        <p>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@escrownia.com" className="text-accent">support@escrownia.com</a>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
