import React, { useState, useEffect } from "react";
import logo from "./assets/escronia.png"; // Update with the correct path to the logo file
import networks from './networks'; // Adjust the path as needed

const Navbar = ({ account, network, setNetwork, connectWallet, disconnectWallet }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState("binance"); // Default to Binance

    // Ensure the selected network is correctly initialized
    useEffect(() => {
        const savedNetwork = localStorage.getItem("selectedNetwork");
        if (savedNetwork && networks[savedNetwork]) {
            setSelectedNetwork(savedNetwork);
            setNetwork(networks[savedNetwork]);
        } else {
            setNetwork(networks.binance); // Default to Binance if no saved network
        }
    }, [setNetwork]);

    // Listen for network changes in MetaMask
    useEffect(() => {
        const { ethereum } = window;
        if (ethereum) {
            ethereum.on("chainChanged", async (chainId) => {
                const matchedNetwork = Object.values(networks).find(
                    (net) => net.chainId === chainId
                );
                if (matchedNetwork) {
                    setNetwork(matchedNetwork);
                    setSelectedNetwork(matchedNetwork.name.toLowerCase());
                    localStorage.setItem("selectedNetwork", matchedNetwork.name.toLowerCase());
                    window.location.reload();
                } else {
                    console.error("Unsupported network switch detected:", chainId);
                }
            });
        }

        return () => {
            if (ethereum) {
                ethereum.removeListener("chainChanged", () => {});
            }
        };
    }, [setNetwork]);

    // Handle manual network switch
    const handleNetworkChange = async (event) => {
        const newNetworkName = event.target.value;
        const selectedNetwork = networks[newNetworkName];
    
        if (selectedNetwork) {
            try {
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: selectedNetwork.chainId }],
                });
                setNetwork(selectedNetwork);
                localStorage.setItem("selectedNetwork", newNetworkName);
                setSelectedNetwork(newNetworkName);
    
                // Update UI without page reload
                window.location.reload();
            } catch (error) {
                console.error("Error switching network in MetaMask:", error.message);
            }
        } else {
            console.error("Invalid network selection:", newNetworkName);
        }
    };
    

    return (
        <nav className="navbar bg-secondary text-white px-4 py-2 flex items-center justify-between">
            {/* Logo Section */}
            <div className="flex items-center">
                <a href="/">
                    <img src={logo} alt="Escrownia Logo" className="h-12 w-12 mr-4" />
                </a>
                <a href="/">
                    <h1 className="text-2xl font-bold">Escrownia</h1>
                </a>
            </div>

            {/* Menu Button for Small Screens */}
            <button
                className="md:hidden block text-white focus:outline-none"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {isMenuOpen ? (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    ) : (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    )}
                </svg>
            </button>

            {/* Menu Section */}
            <div
                className={`md:flex md:items-center ${
                    isMenuOpen ? "block" : "hidden"
                } w-full md:w-auto`}
            >
                {account ? (
                    <div className="md:flex items-center space-y-4 md:space-y-0">
                        {/* Network Selector */}
                        <select
                            value={selectedNetwork}
                            onChange={handleNetworkChange}
                            className="bg-white text-black px-4 py-2 rounded md:mr-4"
                        >
                            {Object.keys(networks).map((key) => (
                                <option key={key} value={key}>
                                    {networks[key].name}
                                </option>
                            ))}
                        </select>

                        {/* Account Info */}
                        <p className="text-accent font-medium text-center md:text-left">
                            Connected: {account.slice(0, 6)}...{account.slice(-4)} ({network.name})
                        </p>

                        {/* Disconnect Button */}
                        <button
                            onClick={disconnectWallet}
                            className="disconnect-button bg-red-500 text-white px-4 py-2 rounded mt-2 md:mt-0 md:ml-4"
                        >
                            Disconnect
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={connectWallet}
                        className="connect-button bg-green-500 text-white px-4 py-2 rounded mt-2 md:mt-0"
                    >
                        Connect Wallet
                    </button>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
