import React from "react";
import Footer from "./Footer";
import Navbar1 from "./NavBar1";

const TermsOfService = () => {
  return (
    <>
      <Navbar1 />
      <div className="container mx-auto py-8 px-6">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

        <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By using our app, you agree to these Terms of Service. If you do not agree, please do not use the app.
        </p>

        <h2 className="text-2xl font-semibold mb-4">2. Use of the App</h2>
        <p className="mb-4">
          - You agree to use the app for lawful purposes only. <br />
          - You may not use the app in a way that disrupts its functionality or compromises security.
        </p>

        <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
        <p className="mb-4">
          - You are responsible for maintaining the confidentiality of your account information. <br />
          - Notify us immediately if you suspect unauthorized use of your account.
        </p>

        <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
        <p className="mb-4">
          All content, trademarks, and logos in the app are the property of Escrownia and may not be used without permission.
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Disclaimer of Warranties</h2>
        <p className="mb-4">
          The app is provided "as is" without any warranties. We do not guarantee uninterrupted service or error-free operation.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
        <p className="mb-4">
          Escrownia is not responsible for any damages arising from your use of the app, including but not limited to data loss or service interruptions.
        </p>

        <h2 className="text-2xl font-semibold mb-4">7. Changes to Terms</h2>
        <p className="mb-4">
          We may update these terms from time to time. Continued use of the app constitutes acceptance of the updated terms.
        </p>

        <p>
          If you have any questions, please contact us at <a href="mailto:support@escrownia.com" className="text-accent">support@escrownia.com</a>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfService;
