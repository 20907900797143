import React from "react";

const ProgressTimeline = ({ progress }) => {
  console.log("ProgressTimeline Progress:", progress); // Debugging log

  return (
      <div className="bg-primary p-6 rounded-lg text-white shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Progress Timeline</h2>
          <ul className="list-disc pl-6 space-y-2">
              {progress.map((entry, index) => (
                  <li
                      key={index}
                      className={`progress-${entry.type} ${
                          entry.type === "error" ? "text-red-400" : "text-accent"
                      } flex items-center`}
                  >
                      <span>{entry.message}</span>
                      {entry.status === "starting" ? (
                          <svg
                              className="animate-spin h-5 w-5 text-accent ml-2"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                          >
                              <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                              ></circle>
                              <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l-1.736 1.736C6.785 20.453 9.268 22 12 22v-4c-1.268 0-2.402-.402-3.309-1.088l-1.691 1.379z"
                              ></path>
                          </svg>
                      ) : null}
                  </li>
              ))}
          </ul>
      </div>
  );
};


export default ProgressTimeline;
