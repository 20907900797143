import React, { useCallback, useEffect, useState } from "react";
import { BrowserProvider, Contract, parseUnits, formatUnits, parseEther, formatEther } from "ethers";
import { toBigInt } from "ethers"; // Add this import
import { isAddress } from "ethers"; // Add this import

import "./App.css";
import contractABI from "./abi/scimatic.json";
import RightColumn from "./RightColumn";
import CreateEscrow from "./CreateEscrow";
import BuyerEscrows from "./BuyerEscrows";
import SellerEscrows from "./SellerEscrows";
import ArbiterEscrows from "./ArbiterEscrows";
import Navbar from "./NavBar";
import Footer from "./Footer";
import networks from './networks';
import LandingPage from "./LandingPage";

// Smart contract details
const usdtABI = [
    "function decimals() public view returns (uint8)",
    "function approve(address spender, uint256 amount) public returns (bool)",
    "function allowance(address owner, address spender) public view returns (uint256)",
];



const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

const PROGRESS_TYPES = {
    INFO: "info",
    START: "starting",
    ERROR: "error",
    SUCCESS: "success",
};

function App() {
    // State variables
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [contract, setContract] = useState(null);
    const [allowance, setAllowance] = useState("");
    const [usdtContract, setUsdtContract] = useState(null);
    const [account, setAccount] = useState("");
    const [escrows, setEscrows] = useState({ buyer: [], seller: [], arbiter: [] });

    const [network, setNetwork] = useState(() => {
        const savedNetwork = localStorage.getItem("selectedNetwork");
        return networks[savedNetwork?.toLowerCase()] || networks.binance; // Fallback to default
    });
    const [selectedToken, setSelectedToken] = useState(network?.tokens?.USDT);


    const [seller, setSeller] = useState("");
    const [amount, setAmount] = useState("");
    const [progress, setProgress] = useState([]);
    const [transactionCommission, setTransactionCommission] = useState("");
    const [disputeCommission, setDisputeCommission] = useState("");
    const [isInitialized, setIsInitialized] = useState(false);
    const [decimals, setDecimals] = useState(18);
    const [isLoading, setIsLoading] = useState(false);

    const contractAddress = network?.contractAddress;
    const tokens = Object.entries(network.tokens).map(([name, address]) => ({
        name,
        address,
    }));

   


    useEffect(() => {
        if (network) {
            // Update available tokens based on selected network
            const defaultToken = network.tokens.USDT; // Default to USDT for the selected network
            setSelectedToken(defaultToken);
            console.log("Network changed, default token set:", defaultToken);
        }
    }, [network]);

    // Utility functions for progress updates
    const updateProgress = (message, type = PROGRESS_TYPES.INFO, status = PROGRESS_TYPES.START) => {
        setProgress((prev) => {
            const existingEntry = prev.find((entry) => entry.message === message);
            if (existingEntry) {
                return prev.map((entry) =>
                    entry.message === message ? { ...entry, type, status } : entry
                );
            }
            return [...prev, { message, type, status }];
        });
    };
    
    


    const markProgressComplete = (message) => {
        setProgress((prev) =>
            prev.map((entry) =>
                entry.message === message ? { ...entry, status: PROGRESS_TYPES.SUCCESS } : entry
            )
        );
    };
    const fetchDisputedEscrows = async () => {
        try {
            const disputedIds = await contract.getDisputedEscrows();
            const disputedDetails = await Promise.all(
                disputedIds.map(async (id) => {
                    const escrow = await contract.escrows(id);
                    return { id: id.toString(), ...escrow };
                })
            );
            setEscrows((prev) => ({ ...prev, disputed: disputedDetails }));
        } catch (error) {
            console.error("Error fetching disputed escrows:", error.message);
        }
    };


    const fetchArbiterEscrows = useCallback(async () => {
        if (!contract || !account) return;

        try {
            // Use provider for read-only operations
            const arbiterIds = await contract.connect(provider).getArbiterEscrows(account);
            console.log("Fetched arbiter IDs:", arbiterIds);

            const fetchDetails = async (ids) => {
                return Promise.all(
                    ids.map(async (id) => {
                        const escrow = await contract.escrows(id);
                        if (await contract.disputed(id)) {
                            return {
                                id: id.toString(),
                                ...escrow,
                                state: Number(escrow.currentState),
                            };
                        }
                        return null;
                    })
                ).then((escrows) => escrows.filter((escrow) => escrow !== null));
            };

            const arbiterEscrows = await fetchDetails(arbiterIds);
            setEscrows((prev) => ({ ...prev, arbiter: arbiterEscrows }));
        } catch (error) {
            console.error("Error fetching arbiter escrows:", error);
        }
    }, [contract, account, provider]);



    // Fetch commissions
    const fetchCommissions = useCallback(async (escrowContract) => {
        try {
            const transactionComm = await escrowContract.transactionCommission();
            const disputeComm = await escrowContract.disputeCommission();
            console.log("comm: ", transactionComm);
            setTransactionCommission(formatEther(transactionComm).toString());
            setDisputeCommission(formatEther(disputeComm).toString());
        } catch (error) {
            console.error("Error fetching commissions:", error);
        }
    }, []);


    // Fetch escrows
    const fetchEscrows = useCallback(async (escrowContract, userAddress) => {
        try {
            const buyerIds = await escrowContract.getBuyerEscrows(userAddress);
            const sellerIds = await escrowContract.getSellerEscrows(userAddress);
            const arbiterIds = await escrowContract.getArbiterEscrows(userAddress);

            const fetchDetails = async (ids) => {
                return Promise.all(
                    ids.map(async (id) => {
                        const escrow = await escrowContract.escrows(id);
                        console.log(escrow);
                        return {
                            id: id.toString(),
                            buyer: escrow.buyer,
                            seller: escrow.seller,
                            arbiter: escrow.arbiter,
                            amount: formatUnits(escrow.amount, decimals),
                            currentState: Number(escrow.currentState),
                            isCompleted: escrow.isCompleted,
                            token: escrow.token,
                        };
                    })
                );
            };

            const buyerDetails = await fetchDetails(buyerIds);
            const sellerDetails = await fetchDetails(sellerIds);
            const arbiterDetails = await fetchDetails(arbiterIds);

            setEscrows({
                buyer: buyerDetails,
                seller: sellerDetails,
                arbiter: arbiterDetails,
            });
        } catch (error) {
            console.error("Error fetching escrows:", error);
        }
    }, [decimals]);


    const fetchAllowance = useCallback(async (usdt, userAddress) => {
        try {
            const currentAllowance = await usdt.allowance(userAddress, contractAddress);
            setAllowance(formatUnits(currentAllowance, decimals)); // Save in state for use
        } catch (error) {
            console.error("Error fetching allowance:", error);
        }
    }, [contractAddress, decimals]);

    useEffect(() => {
        if (contract && account) {
            fetchArbiterEscrows();
        }
    }, [contract, account, fetchArbiterEscrows]);

    const sellerCancel = async (escrowId) => {
        const cancelMessage = `Cancelling escrow ID: ${escrowId}`;

        try {
            updateProgress(cancelMessage, "info", "starting");
            const tx = await contract.sellerCancel(escrowId, {
                value: parseEther(transactionCommission),
            });
            await tx.wait();
            updateProgress(`Escrow ID: ${escrowId} cancelled successfully!`, "success", "finished");

            await fetchEscrows(contract, account);
        } catch (error) {
            updateProgress(`Error cancelling escrow: ${error.message}`, "error", "finished");
        }
    };







    // Connect wallet
    const connectWallet = useCallback(async () => {
        setProgress([]); // Reset progress
        setIsLoading(true); // Start loading spinner

        const message = "Connecting wallet...";
        updateProgress(message, PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);

        if (!window.ethereum) {
            if (isMobileDevice) {
                window.location.href = "https://metamask.app.link/dapp/escrownia.com";
            } else {
                updateProgress(
                    "MetaMask not detected. Please install MetaMask.",
                    PROGRESS_TYPES.ERROR,
                    PROGRESS_TYPES.SUCCESS
                );
            }
            setIsLoading(false); // Ensure loading stops
            return;
        }

        try {
            const web3Provider = new BrowserProvider(window.ethereum);
            //console.log("Web3 provider initialized:", web3Provider);

            await web3Provider.send("eth_requestAccounts", []);
            //console.log("Accounts requested from wallet");

            const web3Signer = await web3Provider.getSigner();
            //console.log("Signer fetched:", web3Signer);

            const userAddress = await web3Signer.getAddress();
            //console.log("User address fetched:", userAddress);

            const currentNetwork = await web3Provider.getNetwork();
            //console.log("Network fetched:", currentNetwork);

            setProvider(web3Provider);
            setSigner(web3Signer);
            setAccount(userAddress);

            let matchedNetwork = Object.values(networks).find(
                (net) => net.chainId === `0x${parseInt(currentNetwork.chainId).toString(16)}`
            );

            if (!matchedNetwork) {
                console.error("Unsupported network, falling back to default.");
                matchedNetwork = networks.ethereum;
            }

            setNetwork(matchedNetwork);
            localStorage.setItem("selectedNetwork", matchedNetwork.name.toLowerCase());
            localStorage.setItem("connectedAccount", userAddress);

            const escrowContract = new Contract(
                matchedNetwork.contractAddress,
                contractABI,
                web3Signer
            );
            setContract(escrowContract);

            const usdt = new Contract(matchedNetwork.tokens.USDT, usdtABI, web3Signer);
            setUsdtContract(usdt);

            console.log("Fetching escrows, allowance, and commissions...");
            await fetchEscrows(escrowContract, userAddress);
            await fetchAllowance(usdt, userAddress);
            await fetchCommissions(escrowContract);

            markProgressComplete(message);
            updateProgress("Wallet connected successfully!", PROGRESS_TYPES.SUCCESS, PROGRESS_TYPES.SUCCESS);
        } catch (error) {
            console.error("Error connecting wallet:", error);
            updateProgress(`Error connecting wallet: ${error.message}`, PROGRESS_TYPES.ERROR, PROGRESS_TYPES.SUCCESS);
        } finally {
            setIsLoading(false); // Ensure spinner stops
        }
    }, [fetchAllowance, fetchCommissions, fetchEscrows]);

  

    // Disconnect wallet
    const disconnectWallet = useCallback(() => {
        const disconnectMessage = "Disconnecting wallet...";
        setIsLoading(true);

        updateProgress(disconnectMessage, PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);

        try {
            setProvider(null);
            setSigner(null);
            setContract(null);
            setUsdtContract(null);
            setAccount("");
            localStorage.removeItem("connectedAccount");
            markProgressComplete(disconnectMessage);
            updateProgress("Wallet disconnected successfully!", PROGRESS_TYPES.SUCCESS, PROGRESS_TYPES.SUCCESS);
        } catch (error) {
            updateProgress(`Error disconnecting wallet: ${error.message}`, PROGRESS_TYPES.ERROR, PROGRESS_TYPES.SUCCESS);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Handle account change
    const handleAccountChange = useCallback(async (accounts) => {
        if (accounts.length === 0) {
            disconnectWallet();
        } else {
            connectWallet();
        }
    }, [connectWallet, disconnectWallet]);

    // Handle network change
    const handleNetworkChange = useCallback(async (chainId) => {
        setIsLoading(true);
        try {
            const matchedNetwork = Object.values(networks).find(
                (net) => net.chainId === `0x${parseInt(chainId, 16).toString(16)}`
            );

            if (matchedNetwork) {
                setNetwork(matchedNetwork);
                localStorage.setItem("selectedNetwork", matchedNetwork.name.toLowerCase());

                const web3Provider = new BrowserProvider(window.ethereum);
                const web3Signer = await web3Provider.getSigner();
                setProvider(web3Provider);
                setSigner(web3Signer);

                const escrowContract = new Contract(
                    matchedNetwork.contractAddress,
                    contractABI,
                    web3Signer
                );
                setContract(escrowContract);

                const usdt = new Contract(matchedNetwork.tokens.USDT, usdtABI, web3Signer);
                setUsdtContract(usdt);

                await fetchCommissions(escrowContract);
            } else {
                console.error("Unsupported network, falling back to default.");
                matchedNetwork(networks.binance);
            }
        } catch (error) {
            console.error("Error handling network change:", error.message);
            setNetwork(networks.ethereum);
        } finally {
            setIsLoading(false);
        }
    }, [fetchCommissions]);

    const initializeContract = useCallback(async (tokenAddress) => {
        if (!window.ethereum) {
            console.error("Ethereum provider not detected. Please install MetaMask.");
            return;
        }

        if (!tokenAddress) {
            console.error("Token address not provided.");
            return;
        }
        if (!isAddress(tokenAddress)) {
            console.error("Invalid token address:", tokenAddress);
            return;
        }

        try {
            console.log("Initializing contract with token address:", tokenAddress);

            const web3Provider = new BrowserProvider(window.ethereum);
            const web3Signer = await web3Provider.getSigner();

            const tokenContract = new Contract(tokenAddress, usdtABI, web3Signer);
            console.log("Token contract initialized:", tokenContract);

            setUsdtContract(tokenContract);

            try {
                const tokenDecimals = await tokenContract.decimals();
                console.log("Token decimals:", tokenDecimals);

                setDecimals(tokenDecimals);
            } catch (error) {
                console.warn("Error fetching decimals, falling back to 18 decimals:", error.message);
                setDecimals(18); // Default to 18 decimals
            }

        } catch (error) {
            console.error("Error initializing contract:", error.message);
            setDecimals(18); // Default to 18 decimals
        }
    }, [setUsdtContract, setDecimals]);





    useEffect(() => {
        if (selectedToken) {
            initializeContract(selectedToken);
        } else {
            console.warn("Selected token is not available for initialization.");
        }
    }, [selectedToken, initializeContract]);





    // useEffect for auto-connect and event listeners
    useEffect(() => {
        const autoConnectMessage = "Auto-connecting wallet...";
        const setupEventListenerMessage = "Setting up account change listener...";

        if (localStorage.getItem("connectedAccount")) {
            updateProgress(autoConnectMessage, PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);

            connectWallet()
                .then(() => {
                    markProgressComplete(autoConnectMessage);
                })
                .catch((error) => {
                    updateProgress(`Error during auto-connect: ${error.message}`, PROGRESS_TYPES.ERROR, PROGRESS_TYPES.SUCCESS);
                });
        }

        const { ethereum } = window;
        if (ethereum) {
            updateProgress(setupEventListenerMessage, PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);

            ethereum.on("accountsChanged", handleAccountChange);
            ethereum.on("chainChanged", handleNetworkChange);

            markProgressComplete(setupEventListenerMessage);
        }

        return () => {
            if (ethereum) {
                ethereum.removeListener("accountsChanged", handleAccountChange);
                ethereum.removeListener("chainChanged", handleNetworkChange);
            }
        };
    }, [connectWallet, handleAccountChange, handleNetworkChange]);

    useEffect(() => {
        const initializeApp = async () => {
            console.log("Initializing App...");

            if (!window.ethereum) {
                console.error("MetaMask not detected.");
                return;
            }

            if (!network?.contractAddress || !network?.tokens.USDT) {
                console.error("Network or contract addresses are not set.");
                return;
            }

            try {
                console.log("Checking contract...");
                if (!usdtContract) await initializeContract(network.tokens.USDT);

                console.log("Contract initialized successfully.");
                setIsInitialized(true);
            } catch (error) {
                console.error("Error initializing app:", error.message);
            }
        };

        initializeApp();
    }, [network, usdtContract, initializeContract]);



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!isInitialized) {
                console.error("Initialization timed out.");
                setIsInitialized(false);
            }
        }, 10000); // 10 seconds timeout

        return () => clearTimeout(timeoutId);
    }, [isInitialized]);

    const checkAndApproveToken = useCallback(
        async (tokenAddress, amount) => {
            if (!signer || !contractAddress) {
                console.error("Signer or contract address is not available.");
                return;
            }
    
            try {
                updateProgress("Checking token allowance...", PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);
                const tokenContract = new Contract(tokenAddress, usdtABI, signer);
                const allowance = await tokenContract.allowance(account, contractAddress);
    
                if (toBigInt(allowance) < parseUnits(amount, decimals)) {
                    updateProgress("Approving token...", PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);
                    const approvalTx = await tokenContract.approve(
                        contractAddress,
                        parseUnits(amount, decimals)
                    );
                    await approvalTx.wait();
                    updateProgress("Token approved successfully!", PROGRESS_TYPES.SUCCESS, "finished");
                } else {
                    updateProgress("Sufficient allowance available.", PROGRESS_TYPES.SUCCESS, "finished");
                }
                markProgressComplete("Checking token allowance...");
            } catch (error) {
                updateProgress(`Error approving token: ${error.message}`, PROGRESS_TYPES.ERROR, "finished");
            }
        },
        [signer, contractAddress, account, decimals]
    );
    





    // Function to create escrow
    const createEscrow = async () => {
        const createEscrowMessage = "Creating escrow...";
        updateProgress(createEscrowMessage, PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);

        setIsLoading(true);
    
        if (!contract || !usdtContract) {
            updateProgress("Connect wallet first!", PROGRESS_TYPES.ERROR, "finished");
            setIsLoading(false);
            return;
        }
    
        if (!seller || !amount) {
            updateProgress("Please fill in all fields (Seller, Amount, Token).", PROGRESS_TYPES.ERROR, "finished");
            setIsLoading(false);
            return;
        }
    
        if (!selectedToken) {
            console.error("No token selected.");
            updateProgress("Please select a token.", PROGRESS_TYPES.ERROR, "finished");
            return;
        }
    
        if (!isAddress(seller)) {
            updateProgress("Invalid seller address.", PROGRESS_TYPES.ERROR, "finished");
            setIsLoading(false);
            return;
        }
    
        if (isNaN(amount) || amount <= 0) {
            updateProgress("Amount must be a positive number.", PROGRESS_TYPES.ERROR, "finished");
            setIsLoading(false);
            return;
        }
    
        try {
            updateProgress("Checking token allowance...", PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);
            await checkAndApproveToken(selectedToken, amount);
            markProgressComplete("Checking token allowance...");
    
            updateProgress("Creating escrow...", PROGRESS_TYPES.INFO, PROGRESS_TYPES.START);
            const tx = await contract.createEscrow(
                seller,
                selectedToken,
                parseUnits(amount.toString(), decimals),
                {
                    value: parseEther(transactionCommission),
                }
            );
            await tx.wait();
            updateProgress("Escrow created successfully!", PROGRESS_TYPES.SUCCESS, "finished");
            markProgressComplete("Creating escrow...");
    
            await fetchEscrows(contract, account);
        } catch (error) {
            updateProgress(`Error creating escrow: ${error.message}`, PROGRESS_TYPES.ERROR, "finished");
        } finally {
            setIsLoading(false);
        }
    };
    




    // Approve payment
    const approvePayment = async (escrowId) => {
        const approvalMessage = `Approving payment for Escrow ID: ${escrowId}`;

        try {
            updateProgress(approvalMessage, "info", "starting");
            const tx = await contract.approvePayment(escrowId, {
                value: parseEther(transactionCommission), // Use the fetched transaction commission
            });
            await tx.wait();
            markProgressComplete(approvalMessage);
            updateProgress(`Payment approved for Escrow ID: ${escrowId}`, "success", "finished");

            // Refresh escrow data after approval
            await fetchEscrows(contract, account);
        } catch (error) {
            markProgressComplete(approvalMessage);
            updateProgress(`Error approving payment: ${error.message}`, "error", "finished");
        }
    };

    const initiateDispute = async (escrowId) => {
        const disputeMessage = `Initiating dispute for Escrow ID: ${escrowId}`;
        setIsLoading(true); // Start spinner

        try {
            updateProgress(disputeMessage, "info", "starting");
            const tx = await contract.initiateDispute(escrowId, {
                value: parseEther(disputeCommission), // Use the fetched dispute commission
            });
            await tx.wait();
            markProgressComplete(disputeMessage);
            updateProgress(`Dispute initiated for Escrow ID: ${escrowId}`, "success", "finished");

            // Refresh escrow data after dispute initiation
            await fetchEscrows(contract, account);
        } catch (error) {
            markProgressComplete(disputeMessage);
            updateProgress(`Error initiating dispute: ${error.message}`, "error", "finished");
        } finally {
            setIsLoading(false); // Stop spinner
        }
    };


    const resolveDispute = async (escrowId, releaseToSeller) => {
        const action = releaseToSeller ? "Release to Seller" : "Refund to Buyer";
        const resolveMessage = `${action} for Escrow ID: ${escrowId}`;
        try {
            updateProgress(resolveMessage, "info", "starting");
            const tx = await contract.resolveDispute(escrowId, releaseToSeller, {
                value: parseEther(disputeCommission), // Use the fetched dispute commission
            });
            await tx.wait();
            markProgressComplete(resolveMessage);
            updateProgress(`${action} completed for Escrow ID: ${escrowId}`, "success", "finished");

            // Refresh escrow data after dispute resolution
            await fetchEscrows(contract, account);
        } catch (error) {
            markProgressComplete(resolveMessage);
            updateProgress(`Error resolving dispute: ${error.message}`, "error", "finished");
        }
    };

 
 

    // Escrow states
    const EscrowStates = {
        PAID_TO_ESCROW: 0,
        DISPUTE: 1,
        COMPLETE: 2,
        REFUNDED: 3
    };
    useEffect(() => {
        console.log("App Progress State Updated:", progress);
    }, [progress]);
    

    const getStateText = (state) => {
        const states = {
            0: "Paid To Escrow",
            1: "Dispute",
            2: "Complete",
            3: "Refunded"
        };
        return states[state] || "Unknown State";
    };

    if (!isInitialized) {
        return (

            <LandingPage />

        );
    }


    return (
        <div className="App">
            <Navbar
                account={account}
                network={network}
                connectWallet={connectWallet}
                disconnectWallet={disconnectWallet}
                setNetwork={setNetwork}
            />
            <div className={`flex flex-col md:flex-row min-h-screen`}>
                <div className={`${account ? "md:w-3/4" : "w-full"} p-4 flex-grow`}>
                    {isLoading && <div className="loading-spinner">Loading...</div>}

                    <main className="content">
                        {account ? (
                            <>


                                <CreateEscrow
                                    seller={seller}
                                    setSeller={setSeller}
                                    amount={amount}
                                    setAmount={setAmount}
                                    selectedToken={selectedToken}
                                    setSelectedToken={setSelectedToken}
                                    tokens={tokens}
                                    createEscrow={createEscrow}
                                />


                                <div>
                                    <h2 className="text-xl font-bold my-4">Escrows</h2>
                                    <BuyerEscrows
                                        escrows={escrows.buyer}
                                        getStateText={getStateText}
                                        approvePayment={approvePayment}
                                        initiateDispute={initiateDispute}
                                        EscrowStates={EscrowStates}
                                    />

                                    <SellerEscrows
                                        escrows={escrows.seller}
                                        getStateText={getStateText}
                                        sellerCancel={sellerCancel}
                                        initiateDispute={initiateDispute}
                                        EscrowStates={EscrowStates}
                                    />

                                    <ArbiterEscrows
                                        escrows={escrows.arbiter}
                                        getStateText={getStateText}
                                        resolveDispute={resolveDispute}
                                        EscrowStates={EscrowStates}
                                    />

                                </div>
                            </>
                        ) : (
                            <LandingPage />
                        )}
                    </main>
                </div>
                {account && (
                    <div className="md:w-1/4 w-full bg-gray-100 dark:bg-gray-800 p-4">
                        <RightColumn
                            transactionCommission={transactionCommission}
                            disputeCommission={disputeCommission}
                            progress={progress} // Ensure progress is passed correctly
                            Coin={network.Coin}
                        />


                    </div>

                )}
            </div>
            <Footer />
        </div>
    );
}

export default App;
