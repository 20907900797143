import React from "react";
import Footer from "./Footer";
import Navbar1 from "./NavBar1";

const About = () => {
  return (
    <>
      <Navbar1 />
      <div className="container mx-auto py-8 px-6">
        <h1 className="text-3xl font-bold mb-6">About Escrownia</h1>
        <p className="mb-4">
          Escrownia is a decentralized payment platform built to provide a secure, transparent, and efficient way of handling transactions. 
          With Escrownia, users can create smart contract-powered escrows to ensure funds are only released when transaction conditions are fulfilled.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Why Choose Escrownia?</h2>
        <p className="mb-4">
          - <b>Secure Transactions:</b> Funds are locked in a smart contract until all parties fulfill the agreement. <br />
          - <b>Dispute Resolution:</b> Easily resolve disputes with the help of a neutral arbiter. <br />
          - <b>Transparency:</b> All transactions are recorded on the blockchain for accountability.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Our Vision</h2>
        <p className="mb-4">
          At Escrownia, we aim to revolutionize online payments by eliminating fraud and ensuring trust between buyers and sellers worldwide.
        </p>

        <p className="mt-6">
          For more information, visit <a href="mailto:support@escrownia.com" className="text-accent">support@escrownia.com</a>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default About;
