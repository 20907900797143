import React from "react";
import logo from "./assets/escronia.png"; // Update with the correct path to the logo file

const Navbar1 = () => {
   
    

    return (
        <nav className="navbar bg-secondary text-white px-4 py-2 flex items-center justify-between">
            {/* Logo Section */}
            <div className="flex items-center">
                <a href="/">
                    <img src={logo} alt="Escrownia Logo" className="h-12 w-12 mr-4" />
                </a>
                <a href="/">
                    <h1 className="text-2xl font-bold">Escrownia</h1>
                </a>
            </div>

           
        </nav>
    );
};

export default Navbar1;
