import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-secondary text-white py-4 px-6 mt-8">
      <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        {/* CopyRight Section */}
        <p className="text-sm text-center md:text-left">
          &copy; {new Date().getFullYear()} Escrownia. All rights reserved.
        </p>

        {/* Links Section */}
        <div className="flex flex-wrap justify-center md:justify-end space-x-4">
          <Link
            to="/privacy-policy"
            className="text-accent hover:text-white transition-colors duration-200"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-of-service"
            className="text-accent hover:text-white transition-colors duration-200"
          >
            Terms of Service
          </Link>
          <Link
            to="/how-to-use"
            className="text-accent hover:text-white transition-colors duration-200"
          >
            How to Use
          </Link>
          <Link
            to="/about"
            className="text-accent hover:text-white transition-colors duration-200"
          >
            About
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
